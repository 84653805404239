import { useEffect } from "react";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "moment/locale/pt-br";
import "react-toastify/dist/ReactToastify.css";

// views
import {
  Login,
  BusRoutes,
  Buses,
  Users,
  RouteMap,
  Feedbacks,
  NewPlacesReport,
  LostAndFound,
} from "views";
// components
import { ProtectedRoute } from "components";
// hooks
import { useStores } from "stores";

function App() {
  // hooks
  const history = useHistory();
  const { auth } = useStores();

  const handleNavigate = () => {
    history?.push("/login");
  };

  useEffect(() => {
    auth.updateUserData(handleNavigate);
  }, []);

  return (
    <>
      <BrowserRouter>
        <Switch>
          <ProtectedRoute exact component={BusRoutes} path="/" />
          <ProtectedRoute exact component={Buses} path="/buses" />
          <ProtectedRoute exact component={Users} path="/users" />
          <ProtectedRoute exact component={RouteMap} path="/route-map" />
          <ProtectedRoute exact component={Feedbacks} path="/feedbacks" />
          <ProtectedRoute
            exact
            component={NewPlacesReport}
            path="/place-report"
          />
          <ProtectedRoute exact component={LostAndFound} path="/lost-found" />
          <Route component={Login} path="/login" />
        </Switch>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
