import { functions, userRef } from "config/firebase";
import { create } from "zustand";

type UsersStore = {
  usersData: UserData[];
  isLoading: boolean;
  todayUsersCount: number;
  usersCount: number;
  loadLimit: number;

  loadUsers: (loadLimit?: number) => void;
  searchByName: (search: string) => void;
};

export const useUserStore = create<UsersStore>((set) => ({
  usersData: [],
  isLoading: false,
  todayUsersCount: 0,
  usersCount: 0,
  loadLimit: 200,

  loadUsers: async (loadLimit = 200) => {
    set({ isLoading: true, loadLimit });

    const { data } = await functions.httpsCallable("getUsers")({
      limit: loadLimit,
    });

    set({
      usersData: data.users,
      usersCount: data.users_count,
      todayUsersCount: data.today_registered,
      isLoading: false,
    });
  },

  searchByName: (search) => {
    set({ isLoading: true });

    userRef
      .where("first_name", ">=", search)
      .where("first_name", "<=", search + "\uf8ff")
      .get()
      .then((snapshot) => {
        const users = [];
        if (snapshot.docs.length > 0) {
          snapshot.forEach((doc) => {
            const data = doc.data();

            data.id = doc.id;
            users.push(data);
          });
        }
        set({ isLoading: false, usersData: users });
      })
      .catch(() => {
        set({ isLoading: false });
      });
  },
}));
