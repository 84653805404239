import {
  Button,
  Container,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@material-ui/core";
import { MapComponent } from "components";
import { BusStopInfoModal } from "components/BusStopInfoModal";
import { useEffect, useRef, useState } from "react";
import { useStores } from "stores";

export function RouteMap() {
  //hooks
  const { busRoutes } = useStores();

  const [routeSelected, setRouteSelected] = useState<BusRoute>();
  const [busStopSelected, setBusStopSelected] = useState({} as BusStop);
  const [showBusStopModal, setShowBusStopModal] = useState(false);
  const [showAllStop, setShowAllStop] = useState(false);
  const [showRouteStop, setShowRouteStop] = useState(false);

  const busStopLatLang = useRef<LatLng>();

  useEffect(() => {
    if (busRoutes.routesData.length === 0) {
      busRoutes.loadRoutes();
    }

    if (busRoutes.busStopsData.length === 0) {
      busRoutes.loadBusStop();
    }
  }, [busRoutes.routesData, busRoutes.busStopsData]);

  const routes = busRoutes.routesData.sort(
    (a, b) => a.route_number - b.route_number,
  );

  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "...";
  };

  const onSelectBusStop = (busStop: BusStop) => {
    setBusStopSelected(busStop);
    setShowBusStopModal(true);
  };

  const handleClosedModal = () => {
    setShowBusStopModal(false);
    busStopLatLang.current = undefined;
  };

  return (
    <Container>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Typography variant="h6">Rotas</Typography>
          <FormControlLabel
            control={
              <Switch
                checked={showRouteStop}
                onChange={() => setShowRouteStop(!showRouteStop)}
                name="checkedB"
                color="primary"
              />
            }
            label="Mostrar pontos rota"
          />
          <FormControlLabel
            control={
              <Switch
                checked={showAllStop}
                onChange={() => setShowAllStop(!showAllStop)}
                name="checkedB"
                color="primary"
              />
            }
            label="Mostrar todos os pontos"
          />
          {routes.map((route) => (
            <Button
              key={route.id}
              variant={
                routeSelected?.id === route.id ? "contained" : "outlined"
              }
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                marginBottom: 5,
              }}
              onClick={() => setRouteSelected(route)}
            >
              <Typography variant="h6" style={{ fontSize: 14 }}>
                {route.route_number} - {route.name} <br />
                <Typography noWrap variant="body2" style={{ fontSize: 12 }}>
                  {truncateText(route.description, 20)}
                </Typography>
              </Typography>
            </Button>
          ))}
        </Grid>
        <Grid item xs={9}>
          <MapComponent
            route={routeSelected}
            busStops={busRoutes.busStopsData}
            busStopLatLang={busStopLatLang}
            showRouteStop={showRouteStop}
            showAllStop={showAllStop}
            onSelectBusStop={onSelectBusStop}
          />
        </Grid>
      </Grid>
      <BusStopInfoModal
        open={showBusStopModal}
        busStop={busStopSelected}
        onClose={handleClosedModal}
      />
    </Container>
  );
}
