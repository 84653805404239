import { Container, styled } from "@material-ui/core";

export const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(3),
  height: "100vh",
  background: "linear-gradient(45deg, #99B9FF 30%, #266FEF 90%)",
}));

export const Content = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "white",
  boxShadow: "0 0 10px 0 rgba(100, 100, 100, 0.1)",
  padding: theme.spacing(2),
  borderRadius: 10,
}));

export const Image = styled("img")(({ theme }) => ({
  objectFit: "contain",
  height: 150,
  width: 150,
  marginBottom: theme.spacing(3),
}));

export const Form = styled("form")(({ theme }) => ({
  marginTop: theme.spacing(1),
  paddingRight: 10,
  paddingLeft: 10,
  borderRadius: 5,
  paddingBottom: 10,
}));

export const Submit = styled("button")(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}));
