import { useEffect } from "react";
import MaterialTable, { Column } from "material-table";
import moment from "moment";
import "moment/locale/pt-br";

// components
import { CircularLoading } from "components";
// hooks
import { useStores } from "stores";

export function Feedbacks() {
  const { feedbacks } = useStores();

  useEffect(() => {
    feedbacks.loadFeedbacks();
  }, []);

  const columns: Column<Feedback>[] = [
    { title: "Nome", field: "user_name", editable: "never" },
    { title: "Telefone", field: "phone", editable: "never" },
    { title: "Mensagem", field: "feedback", editable: "never" },
    {
      title: "Data Envio",
      render: (row) => (
        <p>
          {moment(row.created_at.toDate())
            .locale("pt-br")
            .format("D [de] MMM, YYYY [às] HH:mm")}
        </p>
      ),
    },
  ];

  return feedbacks.isLoading ? (
    <CircularLoading />
  ) : (
    <MaterialTable
      title={`Feedbacks recebidos: ${feedbacks.feedbacksData.length}`}
      columns={columns}
      data={feedbacks.feedbacksData}
      options={{ pageSize: 20 }}
      localization={{
        toolbar: { searchPlaceholder: "Pesquisar" },
        header: { actions: "Excluir" },
        body: {
          editRow: { deleteText: "Excluir esta mensagem ?" },
          emptyDataSourceMessage: "Nenhum feedback encontrado",
        },
        pagination: {
          labelRowsSelect: "Resultados",
          labelDisplayedRows: "{from}-{to} de {count}",
          firstTooltip: "Primeira página",
          previousTooltip: "Página anterior",
          nextTooltip: "Próxima página",
          lastTooltip: "Última página",
        },
      }}
      editable={{
        onRowDelete: (oldData) => feedbacks.deleteFeedback(oldData.id),
      }}
    />
  );
}
