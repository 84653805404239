import { memo, useState } from "react";
import {
  GoogleMap,
  Marker,
  Polyline,
  useJsApiLoader,
} from "@react-google-maps/api";
import "./map.css";

// config
import { REACT_APP_MAP_KEY } from "config/envs";

export const center = {
  lat: -7.192828,
  lng: -48.204862,
};

const containerStyle = {
  flex: 1,
};

type Props = {
  route: BusRoute;
  showRouteStop: boolean;
  showAllStop: boolean;
  busStops: BusStop[];
  busStopLatLang: React.MutableRefObject<LatLng | undefined>;
  onSelectBusStop(busStop: BusStop): void;
};

export const MapComponent = memo(
  ({
    route = {} as BusRoute,
    busStops = [],
    busStopLatLang,
    showRouteStop,
    showAllStop,
    onSelectBusStop,
  }: Props) => {
    const { isLoaded } = useJsApiLoader({
      id: "google-map-script",
      googleMapsApiKey: REACT_APP_MAP_KEY,
      language: "pt-BR",
    });

    const [markerDraggableIndex, setMarkerDraggableIndex] = useState(-1);

    const handleSetdraggable = (index: number) => {
      if (markerDraggableIndex === index) {
        setMarkerDraggableIndex(-1);
      } else {
        setMarkerDraggableIndex(index);
      }
      busStopLatLang.current = undefined;
    };

    const handleClicked = (busStop: BusStop) => {
      setMarkerDraggableIndex(-1);
      const stop = busStop;

      if (busStopLatLang.current) {
        stop.latitude = busStopLatLang.current.latitude;
        stop.longitude = busStopLatLang.current.longitude;
      }

      onSelectBusStop(stop);
    };

    if (!isLoaded) return <div>Carregando...</div>;

    const renderMarkers = () =>
      busStops.map((marker, index) => (
        <Marker
          draggable={markerDraggableIndex === index}
          position={{ lat: marker.latitude, lng: marker.longitude }}
          key={`${index}-${marker.latitude}`}
          label={{
            text: marker.description,
          }}
          icon={"http://maps.google.com/mapfiles/ms/icons/red-dot.png"}
          onClick={() => handleClicked({ ...marker, index })}
          onRightClick={() => handleSetdraggable(index)}
          onDragEnd={(e) => {
            const latitude = e.latLng.lat();
            const longitude = e.latLng.lng();
            busStopLatLang.current = { latitude, longitude };
          }}
        />
      ));

    const renderBusMarkers = () =>
      route.bus_stops?.map((marker, index) => (
        <Marker
          position={{ lat: marker.latitude, lng: marker.longitude }}
          key={`${index}-${marker.latitude}`}
          icon={"https://maps.gstatic.com/mapfiles/ms2/micons/blue-pushpin.png"}
          label={{
            text: marker.description,
          }}
        />
      ));

    return (
      <div style={{ display: "flex", flex: 1 }}>
        <div style={{ height: "600px" }} />
        <GoogleMap
          center={center}
          mapContainerStyle={containerStyle}
          zoom={12.5}
        >
          <Polyline
            path={route.route?.map((item) => ({
              lat: item.latitude,
              lng: item.longitude,
            }))}
            options={{
              strokeColor: "#266FEF",
              strokeOpacity: 1,
              strokeWeight: 3,
            }}
          />
          {showAllStop && renderMarkers()}
          {showRouteStop && renderBusMarkers()}
        </GoogleMap>
      </div>
    );
  },
);
