import { memo, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  makeStyles,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";

// hooks
import { useStores } from "stores";

type Props = {
  open: boolean;
  route: BusRoute;
  onClose(): void;
};

const useStyles = makeStyles({
  select: {
    "& ul": {},
    "& li": {
      fontSize: 14,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      display: "flex",
    },
  },
});

export const ChangeBusModal = memo(({ open, route, onClose }: Props) => {
  const { busRoutes } = useStores();
  const classes = useStyles();

  const [busId, setBusId] = useState("");
  const [keepPreviousBuses, setKeepPreviousBuses] = useState(false);

  const handleUpdate = async () => {
    await busRoutes.updateBusRoute(
      busId,
      route.id,
      route.name,
      keepPreviousBuses,
    );
    handleClose();
  };

  const handleClose = () => {
    setBusId("");
    onClose();
    setKeepPreviousBuses(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Qual ônibus está na rota: {route?.route_number} - {route?.name} ?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Escolha o ônibus</DialogContentText>
        <FormControl
          component="fieldset"
          style={{ width: "100%", maxWidth: 400, marginBottom: 20 }}
        >
          <Select
            name="bues"
            color="secondary"
            value={busId}
            MenuProps={{ classes: { paper: classes.select } }}
            style={{ marginBottom: 30 }}
            fullWidth
            displayEmpty
            onChange={({ target }) => setBusId(target.value as string)}
          >
            <MenuItem value="">Selecione</MenuItem>
            {busRoutes.busesData.map((route) => (
              <MenuItem key={route.id} value={route.id}>
                <b>{route.name}</b>
              </MenuItem>
            ))}
          </Select>
          <FormControlLabel
            control={
              <Checkbox
                checked={keepPreviousBuses}
                onChange={() => setKeepPreviousBuses(!keepPreviousBuses)}
                name="checkedF"
              />
            }
            label="Adicionar como reforço"
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="secondary"
          disabled={busRoutes.isRequestLoading}
        >
          Cancelar
        </Button>
        <Button
          disabled={!busId || busRoutes.isRequestLoading}
          onClick={handleUpdate}
          color="primary"
        >
          {busRoutes.isRequestLoading ? (
            <CircularProgress size={18} />
          ) : (
            "Confimar"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
});
