import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  CircularProgress,
  Typography,
  FormGroup,
  Grid,
  Checkbox,
} from "@material-ui/core";

// hooks
import { useStores } from "stores";
import moment from "moment";

type Props = {
  open: boolean;
  route: BusRoute;
  onClose(): void;
};

export function ScheduleModal({ open, route, onClose }: Props) {
  const { busRoutes, auth } = useStores();

  const [weeklySchedule, setWeeklySchedule] = useState([]);
  const [saturdaySchedule, setSaturdaySchedule] = useState([]);
  const [sundaySchedule, setSundaySchedule] = useState([]);

  const handleRecharge = async () => {
    await busRoutes.updateRouteSchedule(
      route.id,
      weeklySchedule,
      saturdaySchedule,
      sundaySchedule,
    );
    handleClose();
  };

  const handleClose = () => {
    setWeeklySchedule([]);
    setSaturdaySchedule([]);
    setSundaySchedule([]);
    onClose();
  };

  useEffect(() => {
    if (open && route.id) {
      setWeeklySchedule(route.weekly_schedule || []);
      setSaturdaySchedule(route.saturday_schedule || []);
      setSundaySchedule(route.sunday_schedule || []);
    }
  }, [open, route]);

  const isAdmin = auth.userData?.access_type === "admin";
  const hours = [];
  const startTime = moment("05:55", "HH:mm");
  const endTime = moment("23:40", "HH:mm");

  while (startTime.isSameOrBefore(endTime)) {
    hours.push(startTime.format("HH:mm"));
    startTime.add(5, "minutes");
  }

  const handleChange = (
    checked: boolean,
    hour: string,
    type: "week" | "saturday" | "sunday",
  ) => {
    if (type === "week") {
      setWeeklySchedule((prev) =>
        checked ? [...prev, hour] : prev.filter((h) => h !== hour),
      );
    } else if (type === "saturday") {
      setSaturdaySchedule((prev) =>
        checked ? [...prev, hour] : prev.filter((h) => h !== hour),
      );
    } else {
      setSundaySchedule((prev) =>
        checked ? [...prev, hour] : prev.filter((h) => h !== hour),
      );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        Horários da rota: {route?.route_number} - {route?.name}
      </DialogTitle>
      <DialogContent style={{ minWidth: 400 }}>
        <FormControl component="fieldset">
          <Grid container direction="row" spacing={2}>
            <Grid item md>
              <Typography>Semanal</Typography>
              <FormGroup>
                {hours.map((hour) => (
                  <FormControlLabel
                    key={hour}
                    control={<Checkbox color="secondary" />}
                    label={hour}
                    value={hour}
                    disabled={!isAdmin}
                    onChange={(_, checked) =>
                      handleChange(checked, hour, "week")
                    }
                    checked={weeklySchedule.includes(hour)}
                  />
                ))}
              </FormGroup>
            </Grid>
            <Grid item md style={{ background: "#ddd" }}>
              <Typography>Sábado</Typography>
              <FormGroup>
                {hours.map((hour) => (
                  <FormControlLabel
                    key={hour}
                    control={<Checkbox color="primary" />}
                    label={hour}
                    value={hour}
                    disabled={!isAdmin}
                    onChange={(_, checked) =>
                      handleChange(checked, hour, "saturday")
                    }
                    checked={saturdaySchedule.includes(hour)}
                  />
                ))}
              </FormGroup>
            </Grid>
            <Grid item>
              <Typography>Domingo</Typography>
              <FormGroup>
                {hours.map((hour) => (
                  <FormControlLabel
                    key={hour}
                    control={<Checkbox />}
                    label={hour}
                    value={hour}
                    disabled={!isAdmin}
                    onChange={(_, checked) =>
                      handleChange(checked, hour, "sunday")
                    }
                    checked={sundaySchedule.includes(hour)}
                  />
                ))}
              </FormGroup>
            </Grid>
          </Grid>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancelar
        </Button>
        <Button
          onClick={handleRecharge}
          disabled={busRoutes.isRequestLoading}
          color="primary"
        >
          {busRoutes.isRequestLoading ? (
            <CircularProgress size={18} />
          ) : (
            "Salvar"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
