import { useEffect, useState } from "react";
import { Button, CircularProgress, TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";

// assets
import logo from "assets/logo.svg";
// styles
import { Content, Form, Image, Wrapper } from "./styles";
// hooks
import { useStores } from "stores";

export const Login = () => {
  const history = useHistory();
  const { auth } = useStores();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (auth.userData) {
      history.push("/");
    }
  }, [auth.userData]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleNavigate = () => {
    history.push("/");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    auth.signIn(email, password, handleNavigate);
  };

  return (
    <Wrapper>
      <Content maxWidth="xs">
        <Image src={logo} alt="Logo" />
        <Form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            type="email"
            autoComplete="email"
            onChange={handleEmailChange}
            value={email}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            disabled={auth.isLoading}
            style={{ marginTop: 3 }}
          >
            {auth.isLoading ? (
              <CircularProgress color="inherit" size={26} />
            ) : (
              "Entrar"
            )}
          </Button>
        </Form>
      </Content>
    </Wrapper>
  );
};
