import { create } from "zustand";
import { toast } from "react-toastify";

import { busRoutesRef, citiesRef, functions } from "config/firebase";
import { useAuthStore } from "./auth.store";

type BusRoutesStore = {
  routesData: BusRoute[];
  busesData: Bus[];
  busStopsData: BusStop[];
  isLoading: boolean;
  isRequestLoading: boolean;

  updateRoute: (data: BusRoute) => Promise<void>;
  deleteBusStop: (busStopIndex: number) => Promise<void>;
  updateBusStop: (
    busStop: BusStop,
    description: string,
    routes_ids: string[],
  ) => Promise<void>;
  loadRoutes: () => Promise<void>;
  loadBuses: () => Promise<void>;
  loadBusStop: () => Promise<void>;
  updateBusRoute: (
    busId: number | string,
    routeId: string,
    routeName: string,
    keepPreviousBuses?: boolean,
  ) => Promise<void>;
  updateBusMaintenance: (
    busId: number,
    inMaintenance: boolean,
  ) => Promise<void>;
  updateRouteSchedule: (
    routeId: string,
    weeklySchedule: string[],
    saturdaySchedule: string[],
    sundaySchedule: string[],
  ) => Promise<void>;
  removeBusesFromRoute: (routeId: string) => Promise<void>;
};

export const useBusRoutesStore = create<BusRoutesStore>()((set, get) => ({
  routesData: [],
  busesData: [],
  busStopsData: [],
  isLoading: false,
  isRequestLoading: false,

  updateRoute: async (data) => {
    try {
      await busRoutesRef.doc(data.id).update(data);
      await get().loadRoutes();
    } catch (error) {
      console.log(error);
    }
  },

  loadRoutes: async () => {
    set({ isLoading: true });

    try {
      const routesDoc = await busRoutesRef.get();
      const routesData = routesDoc.docs.map((doc) => {
        const route = doc.data() as BusRoute;
        route.id = doc.id;
        return route;
      });

      set({ routesData, isLoading: false });
    } catch (error) {
      set({ isLoading: false });
    }
  },

  loadBusStop: async () => {
    set({ isLoading: true });

    try {
      const cityDoc = await citiesRef.doc("araguaina").get();
      const { bus_stops } = cityDoc.data() as CityInfo;

      set({ isLoading: false, busStopsData: bus_stops });
    } catch (error) {
      set({ isLoading: false });
    }
  },

  loadBuses: async () => {
    set({ isLoading: true });

    try {
      const { data } = await functions.httpsCallable("getBuses")();
      const busesData = data
        .filter((bus: Bus) => !bus.disabled)
        .sort((a: Bus, b: Bus) => a.id - b.id);
      set({ isLoading: false, busesData });
    } catch (error) {
      set({ isLoading: false });
    }
  },

  updateBusRoute: async (busId, routeId, routeName, keepPreviousBuses) => {
    try {
      set({ isRequestLoading: true });
      const authStore = useAuthStore.getState();
      await functions.httpsCallable("setBusRoute")({
        bus_id: busId,
        route_id: routeId,
        route_name: routeName,
        keep_previous_buses: keepPreviousBuses,
        operator_name: authStore.userData.name,
        operator_email: authStore.userData.email,
      });
      await get().loadBuses();
      await get().loadRoutes();
      toast.success("Rota atualizada com sucesso");
      set({ isRequestLoading: false });
    } catch (error) {
      toast.error("Erro ao atualizar rota");
      set({ isRequestLoading: false });
      console.log(error);
    }
  },

  removeBusesFromRoute: async (routeId) => {
    try {
      set({ isRequestLoading: true });
      await functions.httpsCallable("removeAllBusFromRoute")({
        route_id: routeId,
      });
      await get().loadBuses();
      await get().loadRoutes();
      toast.success("Ônibus removidos com sucesso");
      set({ isRequestLoading: false });
    } catch (error) {
      toast.error("Erro ao remover ônibus");
      set({ isRequestLoading: false });
      console.log(error);
    }
  },

  updateBusMaintenance: async (busId, inMaintenance) => {
    try {
      set({ isRequestLoading: true });
      await functions.httpsCallable("setBusMaintenance")({
        bus_id: busId,
        in_maintenance: inMaintenance,
      });
      await get().loadBuses();
      toast.success("Informação atualizada com sucesso");
      set({ isRequestLoading: false });
    } catch (error) {
      toast.error("Erro ao atualizar manutenção");
      set({ isRequestLoading: false });
      console.log(error);
    }
  },

  updateRouteSchedule: async (
    routeId,
    weeklySchedule,
    saturdaySchedule,
    sundaySchedule,
  ) => {
    try {
      set({ isRequestLoading: true });
      await functions.httpsCallable("updateRouteSchedule")({
        route_id: routeId,
        weekly_schedule: weeklySchedule,
        saturday_schedule: saturdaySchedule,
        sunday_schedule: sundaySchedule,
      });
      await get().loadRoutes();
      toast.success("Horários atualizados com sucesso");
      set({ isRequestLoading: false });
    } catch (error) {
      toast.error("Erro ao atualizar horários");
      set({ isRequestLoading: false });
      console.log(error);
    }
  },

  deleteBusStop: async (busStopIndex) => {
    try {
      set({ isRequestLoading: true });
      const busStops = get().busStopsData;
      busStops.splice(busStopIndex, 1);
      await citiesRef.doc("araguaina").update({ bus_stops: busStops });
      toast.success("Parada deletada com sucesso");
      set({ isRequestLoading: false, busStopsData: busStops });
    } catch (error) {
      toast.error("Erro ao deletar parada");
      set({ isRequestLoading: false });
      console.log(error);
    }
  },

  updateBusStop: async (busStop, description, routes_ids) => {
    try {
      set({ isRequestLoading: true });
      const busStops = get().busStopsData;
      busStops[busStop.index] = {
        ...busStop,
        description,
        routes_ids,
      };

      await citiesRef.doc("araguaina").update({ bus_stops: busStops });
      toast.success("Parada atualizada com sucesso");
      set({ isRequestLoading: false, busStopsData: busStops });
    } catch (error) {
      toast.error("Erro ao atualizar parada");
      set({ isRequestLoading: false });
      console.log(error);
    }
  },
}));
