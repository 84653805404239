import { useEffect } from "react";
import MaterialTable, { Column } from "material-table";

// hooks
import { useStores } from "stores";

export function Buses() {
  //hooks
  const { busRoutes } = useStores();

  useEffect(() => {
    busRoutes.loadBuses();
    busRoutes.loadRoutes();
  }, []);

  const columns: Column<Bus>[] = [
    {
      title: "Identificador",
      field: "name",
      editable: "never",
    },
    {
      title: "Rota",
      render: (rowData) => {
        const route = busRoutes.routesData.find(
          (route) => route.id === rowData.attributes.route_id,
        );
        if (!route) return "Sem rota";

        return (
          <>
            <b> {route?.name}</b> - {route?.description}
          </>
        );
      },
      editable: "never",
    },
  ];

  return (
    <MaterialTable
      title={"Ônibus - " + busRoutes.busesData.length}
      isLoading={busRoutes.isLoading}
      columns={columns}
      data={busRoutes.busesData}
      options={{
        pageSize: 150,
        pageSizeOptions: [20, 50, 150],
        emptyRowsWhenPaging: false,
        search: false,
      }}
      localization={{
        toolbar: { searchPlaceholder: "Pesquisar" },
        header: { actions: "Alterar Linha" },
        body: {
          editRow: { deleteText: "Excluir este usuário ?" },
          emptyDataSourceMessage: "Nenhum usuário encontrado",
        },
        pagination: {
          labelRowsSelect: "Resultados",
          labelDisplayedRows: "{from}-{to} de {count}",
          firstTooltip: "Primeira página",
          previousTooltip: "Página anterior",
          nextTooltip: "Próxima página",
          lastTooltip: "Última página",
        },
      }}
    />
  );
}
