import { useAuthStore } from "./auth.store";
import { useBusRoutesStore } from "./bus-routes.stores";
import { useFeedbacksStore } from "./feedbacks.store";
import { useUserStore } from "./users.store";

export const useStores = () => {
  const auth = useAuthStore();
  const busRoutes = useBusRoutesStore();
  const users = useUserStore();
  const feedbacks = useFeedbacksStore();

  return {
    auth,
    users,
    busRoutes,
    feedbacks,
  };
};
