import { useEffect } from "react";
import MaterialTable, { Column } from "material-table";
import moment from "moment";
import "moment/locale/pt-br";

// components
import { CircularLoading } from "components";
// hooks
import { useStores } from "stores";

export function LostAndFound() {
  const { feedbacks } = useStores();

  useEffect(() => {
    feedbacks.loadLostAndFound();
  }, []);

  const columns: Column<LostFound>[] = [
    {
      title: "Aprovado",
      field: "aproved",
      type: "boolean",
    },
    {
      title: "Foto",
      editable: "never",
      render: (row) =>
        row.item_photo && <img src={row.item_photo} height={80} alt="item" />,
    },
    {
      title: "Usuário",
      field: "user_name",
      editable: "never",
      render: (row) => (
        <p>
          {row.user_name} <br />
          {row.user_email}
        </p>
      ),
    },
    { title: "Item", field: "title", editable: "never" },
    { title: "Descrição", field: "description", editable: "never" },
    {
      title: "Tipo",
      editable: "never",
      render: (row) => <p>{row.type === "lost" ? "Perdido" : "Achado"}</p>,
    },
    {
      title: "Data Envio",
      render: (row) => (
        <p>
          {moment(row.created_at.toDate())
            .locale("pt-br")
            .format("D [de] MMM, YYYY [às] HH:mm")}
        </p>
      ),
    },
  ];

  return feedbacks.isLoading ? (
    <CircularLoading />
  ) : (
    <MaterialTable
      title={`Achados, Perdidos: ${feedbacks.lostAndFound.length}`}
      columns={columns}
      data={feedbacks.lostAndFound}
      options={{ pageSize: 20 }}
      localization={{
        toolbar: { searchPlaceholder: "Pesquisar" },
        header: { actions: "Excluir" },
        body: {
          editRow: { deleteText: "Excluir esta mensagem ?" },
          emptyDataSourceMessage: "Nenhum feedback encontrado",
        },
        pagination: {
          labelRowsSelect: "Resultados",
          labelDisplayedRows: "{from}-{to} de {count}",
          firstTooltip: "Primeira página",
          previousTooltip: "Página anterior",
          nextTooltip: "Próxima página",
          lastTooltip: "Última página",
        },
      }}
      editable={{
        onRowDelete: (oldData) => feedbacks.deleteLostAndFound(oldData.id),
        onRowUpdate: (newData) => feedbacks.aproveLostAndFound(newData),
      }}
    />
  );
}
