import { create } from "zustand";

import { feedRef, lostAndFoundRef, newPlacesReportRef } from "config/firebase";

type FeedbacksStore = {
  feedbacksData: Feedback[];
  newPlaceReports: PlaceReport[];
  lostAndFound: LostFound[];
  isLoading: boolean;
  loadFeedbacks: () => void;
  loadPlaceReports: () => void;
  loadLostAndFound: () => void;
  deleteLostAndFound: (id: string) => Promise<void>;
  aproveLostAndFound: (item: LostFound) => Promise<void>;
  deleteFeedback: (rideId: string) => Promise<void>;
  deletePlaceReport: (rideId: string) => Promise<void>;
};

export const useFeedbacksStore = create<FeedbacksStore>()((set, get) => ({
  feedbacksData: [],
  newPlaceReports: [],
  lostAndFound: [],
  isLoading: false,

  loadFeedbacks: () => {
    set({ isLoading: true });
    feedRef
      .orderBy("created_at", "desc")
      .get()
      .then((snapshot) => {
        const arr = [];
        if (snapshot.docs.length > 0) {
          snapshot.forEach((doc) => {
            const data = doc.data();
            data.id = doc.id;
            arr.push(data);
          });
        }
        set({ feedbacksData: arr, isLoading: false });
      });
  },

  loadPlaceReports: () => {
    set({ isLoading: true });
    newPlacesReportRef
      .orderBy("created_at", "desc")
      .get()
      .then((snapshot) => {
        const arr = [];
        if (snapshot.docs.length > 0) {
          snapshot.forEach((doc) => {
            const data = doc.data();
            data.id = doc.id;
            arr.push(data);
          });
        }
        set({ newPlaceReports: arr, isLoading: false });
      });
  },

  loadLostAndFound: () => {
    set({ isLoading: true });
    lostAndFoundRef
      .orderBy("created_at", "desc")
      .get()
      .then((snapshot) => {
        const arr = [];
        if (snapshot.docs.length > 0) {
          snapshot.forEach((doc) => {
            const data = doc.data();
            data.id = doc.id;
            arr.push(data);
          });
        }
        set({ lostAndFound: arr, isLoading: false });
      });
  },

  aproveLostAndFound: async (item) => {
    await lostAndFoundRef.doc(item.id).update({ aproved: item.aproved });
    get().loadLostAndFound();
  },

  deleteFeedback: async (id) => {
    await feedRef.doc(id).delete();
    get().loadFeedbacks();
  },

  deletePlaceReport: async (id) => {
    await newPlacesReportRef.doc(id).delete();
    get().loadPlaceReports();
  },

  deleteLostAndFound: async (id) => {
    await lostAndFoundRef.doc(id).delete();
    get().loadLostAndFound();
  },
}));
