import { useEffect } from "react";
import MaterialTable, { Column } from "material-table";
import { Button } from "@material-ui/core";
import moment from "moment";

// assets
import profile_pic from "assets/profile.png";
// components
import { Toolbar } from "./components/Toolbar";
import { CircularLoading } from "components";
// hooks
import { useStores } from "stores";

export function Users() {
  // hooks
  const { users } = useStores();

  useEffect(() => {
    users.loadUsers();
  }, []);

  const handleLoadMore = () => {
    const loadLimit = users.loadLimit + 60;
    users.loadUsers(loadLimit);
  };

  const renderDate = (row) => {
    return (
      <p>
        {moment(row?.created_at?._seconds * 1000)
          .locale("pt-br")
          .format("D [de] MMM, YYYY")}
      </p>
    );
  };

  const formatDate = (createdAt) => {
    let date = createdAt;
    if (createdAt.seconds) {
      date = new Date(createdAt.toDate()).toUTCString();
    }

    return date;
  };

  const columns: Column<UserData>[] = [
    {
      title: "Foto",
      field: "profile_image",
      editable: "never",
      align: "center",
      render: (rowData) => (
        <img
          alt="Profile"
          src={rowData.photo_url || profile_pic}
          style={{ width: 50, borderRadius: "50%" }}
        />
      ),
    },
    {
      title: "Nome",
      field: "first_name",
      editable: "never",
      render: (rowData) => {
        if (!rowData.first_name) return "Usuário Anônimo";
        return (
          <p>
            {rowData.first_name} {rowData.last_name}
          </p>
        );
      },
    },
    { title: "Email", field: "email" },
    {
      title: "Cadastro",
      field: "created_at",
      customSort: (a, b) =>
        new Date(formatDate(a.created_at)).getTime() -
        new Date(formatDate(b.created_at)).getTime(),
      render: renderDate,
    },
  ];

  return (
    <>
      <Toolbar
        searchByName={users.searchByName}
        resetData={users.loadUsers}
        usersCont={users.usersCount}
        todayUsersCount={users.todayUsersCount}
      />
      {users.isLoading ? (
        <CircularLoading />
      ) : (
        <MaterialTable
          columns={columns}
          data={users.usersData}
          options={{
            pageSize: users.usersData.length,
            pageSizeOptions: [20, 50, 150],
            emptyRowsWhenPaging: false,
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Nenhuma usuário encontrado",
            },
          }}
          components={{
            Toolbar: () => null,
            Pagination: () => (
              <Button
                onClick={handleLoadMore}
                color="primary"
                variant="contained"
                disabled={users.usersData.length === users.usersCount}
                style={{ margin: 20 }}
              >
                {users.usersData.length} de {users.usersCount.toLocaleString()}{" "}
                - Carregar mais...
              </Button>
            ),
          }}
        />
      )}
    </>
  );
}
