import { memo } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";

// hooks
import { useStores } from "stores";

type Props = {
  open: boolean;
  route: BusRoute;
  onClose(): void;
};

export const ConfirmRemoBusModal = memo(({ open, route, onClose }: Props) => {
  const { busRoutes } = useStores();

  const handleUpdate = async () => {
    await busRoutes.removeBusesFromRoute(route.id);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Remover todos os ônibus da rota {route?.route_number} - {route?.name} ?
      </DialogTitle>
      <DialogActions>
        <Button
          onClick={onClose}
          color="secondary"
          disabled={busRoutes.isRequestLoading}
        >
          Cancelar
        </Button>
        <Button
          disabled={busRoutes.isRequestLoading}
          onClick={handleUpdate}
          color="primary"
        >
          {busRoutes.isRequestLoading ? (
            <CircularProgress size={18} />
          ) : (
            "Confimar"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
});
